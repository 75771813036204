import { BatchRequestProcessor } from 'core-utilities';
import { EnvironmentUrls } from 'Roblox';

const { thumbnailsApi } = EnvironmentUrls;

export const ThumbnailMetadataUrl = `${thumbnailsApi}/v1/metadata`;

export enum ThumbnailTypes {
  assetThumbnail = 'assetThumbnail',
  avatarHeadshot = 'avatarHeadshot',
  avatar = 'avatar',
  bundleThumbnail = 'bundleThumbnail',
  gameIcon = 'gameIcon',
  groupIcon = 'groupIcon',
  badgeIcon = 'badgeIcon',
  gamePassIcon = 'gamePassIcon',
  developerProductIcon = 'developerProductIcon',
  userOutfit = 'userOutfit',
  gameThumbnail = 'gameThumbnail'
}

export const DefaultBatchSize = 100;

export enum ThumbnailCooldown {
  maxRetryAttempts = 10,
  minCooldown = 1000,
  maxCooldown = 30000
}

// expand when batchRequestProcessor is implemented
export interface ThumbnailRequesters {
  [key: string]: BatchRequestProcessor;
}

export interface ThumbnailCache {
  [key: string]: object;
}

export const DefaultThumbnailSize = '150x150';

export const DefaultThumbnailFormat = 'png';

export enum ThumbnailGameIconSize {
  size50 = '50x50',
  size150 = '150x150',
  size512 = '512x512'
}

export enum ThumbnailGamePassIconSize {
  size150 = '150x150'
}

export enum ThumbnailAssetsSize {
  size150 = '150x150',
  size420 = '420x420'
}

export enum ThumbnailAvatarsSize {
  size100 = '100x100',
  size352 = '352x352',
  size720 = '720x720'
}

export enum ThumbnailAvatarHeadshotSize {
  size48 = '48x48',
  size60 = '60x60',
  size150 = '150x150'
}

export enum ThumbnailGroupIconSize {
  size150 = '150x150',
  size420 = '420x420'
}

export enum ThumbnailBadgeIconSize {
  size150 = '150x150'
}

export enum ThumbnailDeveloperProductIconSize {
  size150 = '150x150'
}

export enum ThumbnailGameThumbnailSize {
  width768 = '768x432',
  width576 = '576x324',
  width480 = '480x270',
  width384 = '384x216',
  width256 = '256x144'
}

export enum ThumbnailStates {
  error = 'Error',
  complete = 'Completed',
  inReview = 'InReview',
  pending = 'Pending',
  blocked = 'Blocked'
}

export enum ThumbnailFormat {
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg'
}

export enum ReturnPolicy {
  PlaceHolder = 'PlaceHolder',
  AutoGenerated = 'AutoGenerated',
  ForceAutoGenerated = 'ForceAutoGenerated'
}

export interface ThumbnailPendingTrackerItem {
  startTime: number;
}

export interface ThumbnailCompleteTrackerItem {
  startTime: number;
}

export interface ThumbnailPendingTracker {
  [key: string]: ThumbnailPendingTrackerItem;
}

export interface ThumbnailCompleteTracker {
  [key: string]: ThumbnailCompleteTrackerItem;
}
export interface ThumbnailPerformance {
  duration: number;
}
export interface ThumbnailDataItem {
  targetId: number;
  state: ThumbnailStates;
  imageUrl?: string;
  performance?: ThumbnailPerformance;
}

export interface ThumbnailData {
  data: Array<object>;
}

export interface ThumbnailDataData {
  data: ThumbnailData;
}

export interface MetaData {
  isWebappUseCacheEnabled: boolean;
  webappCacheExpirationTimspan: string;
  thumbnailMetricsSampleSize: number;
  requestMinCooldown: number;
  requestMaxCooldown: number;
  requestMaxRetryAttempts: number;
  requestBatchSize: number;
}

export interface MetaDataTask {
  resolve: (metaData: MetaData) => void;
  reject: (error: string) => void;
}

export interface MetaDataResponse {
  data?: MetaData;
}

export const FeatureName = 'Thumbnail2DWeb';
export const LoadSuccessName = 'LoadSuccess';
export const LoadSuccessMetricsType = 'Sequence';
export const LoadFailureName = 'LoadFailure';
export const LoadFailureMetricsType = 'Counter';
