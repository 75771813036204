import { httpService } from 'core-utilities';
import {
  ThumbnailMetadataUrl,
  MetaDataTask,
  MetaDataResponse,
  MetaData
} from '../constants/thumbnail2dConstant';

const getMetaDataFromPage = () => {
  const metaElement = document.getElementsByName('thumbnail-meta-data')[0];
  if (metaElement) {
    return {
      thumbnailMetricsSampleSize: parseInt(
        metaElement.getAttribute('data-thumbnail-metrics-sample-size'),
        10
      ),
      isWebappUseCacheEnabled: metaElement.getAttribute('data-is-webapp-cache-enabled') === 'True',
      webappCacheExpirationTimspan: metaElement.getAttribute(
        'data-webapp-cache-expirations-timespan'
      ),
      requestMinCooldown: parseInt(metaElement.getAttribute('data-request-min-cooldown')),
      requestMaxCooldown: parseInt(metaElement.getAttribute('data-request-max-cooldown')),
      requestMaxRetryAttempts: parseInt(metaElement.getAttribute('data-request-max-retry-attempts')),
      requestBatchSize: parseInt(metaElement.getAttribute('data-request-batch-size'))
    };
  }
  return null;
};

let thumbnailMetaData: MetaData = getMetaDataFromPage();
let requestSent = false;
const requestQueue: Set<MetaDataTask> = new Set();

const processQueue = (error?: string) => {
  if (error) {
    requestQueue.forEach(item => {
      item.reject(error);
    });
    requestQueue.clear();
    return;
  }
  if (thumbnailMetaData) {
    requestQueue.forEach(item => {
      item.resolve(thumbnailMetaData);
    });
    requestQueue.clear();
    requestSent = true;
    return;
  }
  const urlConfig = {
    url: ThumbnailMetadataUrl,
    withCredentials: true
  };

  if (!requestSent) {
    // ensures api only gets called once
    // during page load with no retry
    requestSent = true;
    httpService
      .get(urlConfig)
      .then((metadata: MetaDataResponse) => {
        thumbnailMetaData = metadata?.data;
        processQueue();
      })
      .catch((error: string) => {
        console.debug(error);
        processQueue(error);
      });
    return;
  }
};

const getThumbnailMetaData = () => {
  return new Promise((resolve, reject) => {
    requestQueue.add({ resolve, reject });
    setTimeout(processQueue, 50);
  });
};

export default {
  getThumbnailMetaData
};
