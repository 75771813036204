import thumbnail2dService from '../../../../ts/2d/services/thumbnail2d';
import thumbnailsModule from '../thumbnailsModule';

function thumbnailService($q) {
  'ngInject';

  const getThumbnailImage = (thumbnailType, targetId, size) => {
    return $q((resolve, reject) => {
      thumbnail2dService
        .getThumbnailImage(thumbnailType, targetId, size)
        .then(image => {
          resolve(image);
        })
        .catch(reject);
    });
  };

  const reloadThumbnailImage = (thumbnailType, targetId, size) => {
    return $q((resolve, reject) => {
      thumbnail2dService
        .reloadThumbnailImage(thumbnailType, targetId, size)
        .then(image => {
          resolve(image);
        })
        .catch(reject);
    });
  };

  const getCssClass = thumbnailState => {
    return thumbnail2dService.getCssClass(thumbnailState);
  };

  return {
    getThumbnailImage,
    getCssClass,
    reloadThumbnailImage
  };
}

thumbnailsModule.factory('thumbnailService', thumbnailService);

export default thumbnailService;
