import { BatchRequestError, QueueItem } from 'core-utilities';
import { dataStores } from 'core-roblox-utilities';
import thumbnailRequester from '../util/thumbnailRequester';

import {
  ThumbnailTypes,
  ThumbnailAssetsSize,
  ThumbnailGameIconSize,
  ThumbnailGamePassIconSize,
  ThumbnailGameThumbnailSize,
  ThumbnailGroupIconSize,
  ThumbnailBadgeIconSize,
  ThumbnailDeveloperProductIconSize,
  ThumbnailAvatarsSize,
  ThumbnailStates,
  ThumbnailFormat,
  ReturnPolicy,
  ThumbnailDataData,
  ThumbnailDataItem,
  ThumbnailAvatarHeadshotSize
} from '../constants/thumbnail2dConstant';

const { processThumbnailBatchRequest } = thumbnailRequester;

const { thumbnailsDataStore, gameThumbnailsDataStore } = dataStores;

const dataStoreInstanceMap = {
  [ThumbnailTypes.assetThumbnail]: thumbnailsDataStore.getAssets,
  [ThumbnailTypes.avatarHeadshot]: thumbnailsDataStore.getAvatarHeadshots,
  [ThumbnailTypes.avatar]: thumbnailsDataStore.getAvatars,
  [ThumbnailTypes.bundleThumbnail]: thumbnailsDataStore.getBundles,
  [ThumbnailTypes.gameIcon]: gameThumbnailsDataStore.getIcons,
  [ThumbnailTypes.groupIcon]: thumbnailsDataStore.getGroupIcons,
  [ThumbnailTypes.badgeIcon]: thumbnailsDataStore.getBadgeIcons,
  [ThumbnailTypes.gamePassIcon]: gameThumbnailsDataStore.getGamePassIcons,
  [ThumbnailTypes.developerProductIcon]: thumbnailsDataStore.getDeveloperProductIcons,
  [ThumbnailTypes.userOutfit]: thumbnailsDataStore.getUserOutfits,
  [ThumbnailTypes.gameThumbnail]: gameThumbnailsDataStore.getThumbnailsByThumbnailIds
};

const getDataStoreInstance = (thumbnailType: ThumbnailTypes) => {
  const dataStoreInstance = dataStoreInstanceMap[thumbnailType];
  if (!dataStoreInstance) {
    console.debug(`Missing urlConfig implementation for thumbnail type: ${thumbnailType}`);
  }
  return dataStoreInstance;
};

const getThumbnailRequesterKey = (
  baseTargetId: number,
  thumbnailType: ThumbnailTypes,
  size:
    | ThumbnailAssetsSize
    | ThumbnailGameIconSize
    | ThumbnailGameThumbnailSize
    | ThumbnailGamePassIconSize
    | ThumbnailAvatarsSize
    | ThumbnailAvatarHeadshotSize
    | ThumbnailGroupIconSize
    | ThumbnailBadgeIconSize
    | ThumbnailDeveloperProductIconSize,
  format: ThumbnailFormat = ThumbnailFormat.png
) => {
  return `${baseTargetId}:${thumbnailType}:${size}:${format}`;
};

const handleBatchRequest = (
  baseTargetId: number,
  items: Array<QueueItem>,
  thumbnailType: ThumbnailTypes,
  size:
    | ThumbnailAssetsSize
    | ThumbnailGameIconSize
    | ThumbnailGameThumbnailSize
    | ThumbnailGamePassIconSize
    | ThumbnailAvatarsSize
    | ThumbnailAvatarHeadshotSize
    | ThumbnailGroupIconSize
    | ThumbnailBadgeIconSize
    | ThumbnailDeveloperProductIconSize,
  format: ThumbnailFormat = ThumbnailFormat.png
) => {
  const thumbnailDataStoreInstance = getDataStoreInstance(thumbnailType);
  return new Promise((resolve, reject) => {
    const ids = items.map(({ itemId }) => itemId);

    if (!thumbnailDataStoreInstance) {
      reject(BatchRequestError.unretriableFailure);
      return;
    }

    let dataStoreArgs: Array<any> = [];
    if (
      thumbnailType === ThumbnailTypes.assetThumbnail ||
      thumbnailType === ThumbnailTypes.gameIcon
    ) {
      dataStoreArgs = [ids, ReturnPolicy.PlaceHolder, size, format];
    } else if (thumbnailType === ThumbnailTypes.gameThumbnail) {
      dataStoreArgs = [baseTargetId, ids, size, format];
    } else {
      dataStoreArgs = [ids, size, format];
    }

    thumbnailDataStoreInstance
      .apply(null, dataStoreArgs)
      .then((thumbnailData: ThumbnailDataData) => {
        const thumbnailResults: Map<number, ThumbnailDataItem> = new Map();
        const results: { [key: string]: ThumbnailDataItem } = {};
        const itemsData = thumbnailData?.data?.data ?? [];
        itemsData.forEach((item: ThumbnailDataItem) => {
          thumbnailResults.set(item.targetId, item);
        });
        items.forEach(item => {
          const { itemId, key } = item;
          const id = parseInt(`${itemId}`, 10);
          if (thumbnailResults.has(id)) {
            if (thumbnailResults.get(id).state !== ThumbnailStates.pending) {
              results[key] = thumbnailResults.get(id);
            }
          } else {
            results[key] = {
              targetId: id,
              state: ThumbnailStates.error
            };
          }
        });
        resolve(results);
      })
      .catch(() => {
        // No success cases this time, all will be retried.
        resolve({});
      });
  });
};

const loadThumbnailImage = (
  thumbnailType: ThumbnailTypes,
  baseTargetId: number,
  targetId: number,
  size:
    | ThumbnailAssetsSize
    | ThumbnailGameIconSize
    | ThumbnailGameThumbnailSize
    | ThumbnailGamePassIconSize
    | ThumbnailAvatarsSize
    | ThumbnailAvatarHeadshotSize
    | ThumbnailGroupIconSize
    | ThumbnailBadgeIconSize
    | ThumbnailDeveloperProductIconSize,
  format: ThumbnailFormat = ThumbnailFormat.png,
  clearCachedValue?: boolean
) => {
  if (!targetId) {
    return new Promise((resolve, reject) => {
      reject('TargetId can not be empty.');
    });
  }

  if (!thumbnailType) {
    return new Promise((resolve, reject) => {
      reject('ThumbnailType can not be empty.');
    });
  }

  const thumbnailRequesterKey = getThumbnailRequesterKey(baseTargetId, thumbnailType, size, format);

  return processThumbnailBatchRequest(
    targetId,
    (items: Array<QueueItem>) =>
      handleBatchRequest(baseTargetId, items, thumbnailType, size, format),
    thumbnailRequesterKey,
    clearCachedValue
  );
};

const getThumbnailImage = (
  thumbnailType: ThumbnailTypes,
  targetId: number,
  size:
    | ThumbnailAssetsSize
    | ThumbnailGameIconSize
    | ThumbnailGameThumbnailSize
    | ThumbnailGamePassIconSize
    | ThumbnailAvatarsSize
    | ThumbnailAvatarHeadshotSize
    | ThumbnailGroupIconSize
    | ThumbnailBadgeIconSize
    | ThumbnailDeveloperProductIconSize,
  format: ThumbnailFormat = ThumbnailFormat.png
) => {
  return loadThumbnailImage(thumbnailType, null, targetId, size, format, false);
};

const reloadThumbnailImage = (
  thumbnailType: ThumbnailTypes,
  targetId: number,
  size:
    | ThumbnailAssetsSize
    | ThumbnailGameIconSize
    | ThumbnailGameThumbnailSize
    | ThumbnailGamePassIconSize
    | ThumbnailAvatarsSize
    | ThumbnailAvatarHeadshotSize
    | ThumbnailGroupIconSize
    | ThumbnailBadgeIconSize
    | ThumbnailDeveloperProductIconSize,
  format: ThumbnailFormat = ThumbnailFormat.png
) => {
  return loadThumbnailImage(thumbnailType, null, targetId, size, format, true);
};

const getThumbnailImageWithBaseTarget = (
  thumbnailType: ThumbnailTypes,
  baseTargetId: number,
  targetId: number,
  size:
    | ThumbnailAssetsSize
    | ThumbnailGameIconSize
    | ThumbnailGameThumbnailSize
    | ThumbnailGamePassIconSize
    | ThumbnailAvatarsSize
    | ThumbnailAvatarHeadshotSize
    | ThumbnailGroupIconSize
    | ThumbnailBadgeIconSize
    | ThumbnailDeveloperProductIconSize,
  format: ThumbnailFormat = ThumbnailFormat.png
) => {
  return loadThumbnailImage(thumbnailType, baseTargetId, targetId, size, format, false);
};

const getCssClass = (thumbnailState: ThumbnailStates) => {
  return {
    'icon-broken': thumbnailState === ThumbnailStates.error,
    'icon-in-review': thumbnailState === ThumbnailStates.inReview,
    'icon-blocked': thumbnailState === ThumbnailStates.blocked,
    'icon-pending': thumbnailState === ThumbnailStates.pending
  };
};

// @ts-ignore
export default window.RobloxThumbnails?.thumbnailService || {
  getThumbnailImage,
  getCssClass,
  reloadThumbnailImage,
  getThumbnailImageWithBaseTarget
};
