import React, { useState, useEffect } from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import thumbnailService from '../../../../ts/2d/services/thumbnail2d';
import Thumbnail from '../components/Thumbnail';

function Thumbnail2dContainer({
  type,
  baseTargetId,
  targetId,
  size,
  imgClassName,
  containerClass,
  format,
  altName
}) {
  const [thumbnailStatus, setImageStatus] = useState(null);
  const [thumbnailUrl, setImageUrl] = useState(null);
  const errorIconClass = ClassNames(thumbnailService.getCssClass(thumbnailStatus));
  const [shimmerClass, setShimmerClass] = useState('shimmer');

  useEffect(() => {
    setShimmerClass('shimmer');
    setImageStatus(null);
    setImageUrl(null);

    let isUnmounted = false;
    let getImagePromise;
    if (baseTargetId) {
      getImagePromise = thumbnailService.getThumbnailImageWithBaseTarget(
        type,
        baseTargetId,
        targetId,
        size,
        format
      );
    } else {
      getImagePromise = thumbnailService.getThumbnailImage(type, targetId, size, format);
    }

    getImagePromise
      .then(({ state, imageUrl }) => {
        if (!isUnmounted) {
          setImageStatus(state);
          setImageUrl(imageUrl);
          setShimmerClass('');
        }
      })
      .catch(err => {
        console.debug(err);
        if (!isUnmounted) {
          setShimmerClass('');
        }
      });

    return () => {
      isUnmounted = true;
    };
  }, [type, targetId, size, imgClassName]);

  return (
    <Thumbnail
      {...{
        thumbnailUrl,
        errorIconClass,
        imgClassName,
        altName,
        containerClass: ClassNames(shimmerClass, containerClass)
      }}
    />
  );
}
Thumbnail2dContainer.defaultProps = {
  baseTargetId: 0,
  size: '150x150',
  imgClassName: '',
  containerClass: '',
  format: 'png',
  altName: ''
};

Thumbnail2dContainer.propTypes = {
  type: PropTypes.string.isRequired,
  baseTargetId: PropTypes.number,
  targetId: PropTypes.number.isRequired,
  size: PropTypes.string,
  format: PropTypes.string,
  imgClassName: PropTypes.string,
  containerClass: PropTypes.string,
  altName: PropTypes.string
};

export default Thumbnail2dContainer;
