import { ThumbnailMetrics } from 'Roblox';
import { batchRequestFactory, BatchItemProcessor } from 'core-utilities';
import { metricsService } from 'core-roblox-utilities';
import thumbnailMetaData from '../services/thumbnailMetaData';
import {
  DefaultBatchSize,
  ThumbnailCooldown,
  ThumbnailRequesters,
  ThumbnailDataItem,
  FeatureName,
  LoadFailureName,
  LoadSuccessMetricsType,
  LoadSuccessName,
  LoadFailureMetricsType,
  MetaData
} from '../constants/thumbnail2dConstant';

const { getThumbnailMetaData } = thumbnailMetaData;

const defaultThumbnailRequesterProperties = {
  getFailureCooldown: batchRequestFactory.createExponentialBackoffCooldown(
    ThumbnailCooldown.minCooldown,
    ThumbnailCooldown.maxCooldown
  ),
  maxRetryAttempts: ThumbnailCooldown.maxRetryAttempts,
  batchSize: DefaultBatchSize
};

const getThumbnailRequesterProperties = (metaData?: MetaData) => {
  if (!metaData) return defaultThumbnailRequesterProperties;
  return {
    getFailureCooldown: batchRequestFactory.createExponentialBackoffCooldown(
      metaData.requestMinCooldown,
      metaData.requestMaxCooldown
    ),
    maxRetryAttempts: metaData.requestMaxRetryAttempts,
    batchSize: metaData.requestBatchSize
  };
};

const thumbnailRequesters: ThumbnailRequesters = {};

const getThumbnailRequester = (
  thumbnailRequestProcessor: BatchItemProcessor,
  thumbnailRequesterKey: string,
  metaData?: MetaData
) => {
  if (thumbnailRequesterKey in thumbnailRequesters) {
    return thumbnailRequesters[thumbnailRequesterKey];
  }

  const processor = batchRequestFactory.createRequestProcessor(
    thumbnailRequestProcessor,
    id => `${thumbnailRequesterKey}:${id}`,
    getThumbnailRequesterProperties(metaData)
  );
  thumbnailRequesters[thumbnailRequesterKey] = processor;
  return processor;
};

const getExpirationMsFromString = (timeSpan: string) => {
  const timeSpanFormat = timeSpan.split(':');
  return (
    (parseInt(timeSpanFormat[0], 10) * 60 * 60 +
      parseInt(timeSpanFormat[1], 10) * 60 +
      parseInt(timeSpanFormat[2], 10)) *
    1000
  );
};

const getCachePropertiesFromMetaData = (metaData?: MetaData) => {
  if (!metaData) return null;
  return {
    useCache: metaData.isWebappUseCacheEnabled,
    expirationWindowMS: getExpirationMsFromString(metaData.webappCacheExpirationTimspan)
  };
};

const shouldLogMetrics = (metaData?: MetaData) => {
  if (!metaData) return true;
  const { thumbnailMetricsSampleSize } = metaData;
  return Math.floor(Math.random() * 100) <= thumbnailMetricsSampleSize;
};

const processThumbnailBatchRequest = (
  targetId: number,
  thumbnailRequestProcessor: BatchItemProcessor,
  thumbnailRequesterKey: string,
  clearCachedValue: boolean
) => {
  const queueItem = (metaData?: MetaData) => {
    const batchRequester = getThumbnailRequester(
      thumbnailRequestProcessor,
      thumbnailRequesterKey,
      metaData
    );
    if (clearCachedValue) {
      batchRequester.invalidateItem(targetId);
    }

    const cacheProperties = getCachePropertiesFromMetaData(metaData);
    return batchRequester
      .queueItem(targetId, null, cacheProperties)
      .then((data: ThumbnailDataItem) => {
        if (metricsService && data.performance && shouldLogMetrics(metaData)) {
          const {
            performance: { duration }
          } = data;
          metricsService
            .logMeasurement({
              featureName: FeatureName,
              measureName: LoadSuccessName,
              metricsType: LoadSuccessMetricsType,
              excludeCountry: true,
              value: duration
            })
            .catch(console.debug);
        }
        return data;
      })
      .catch((error: object) => {
        console.debug({ error });
        if (metricsService && shouldLogMetrics(metaData)) {
          metricsService
            .logMeasurement({
              featureName: FeatureName,
              measureName: LoadFailureName,
              metricsType: LoadFailureMetricsType,
              excludeCountry: true
            })
            .catch(console.debug);
        }

        if (ThumbnailMetrics) {
          ThumbnailMetrics.logThumbnailTimeout();
        }
        // chain the rejection so that other listeners get triggered.
        return Promise.reject(error);
      });
  };

  return getThumbnailMetaData()
    .then(queueItem)
    .catch(error => {
      console.debug(error);
      // continue queue item without metaData
      return queueItem();
    });
};

export default {
  processThumbnailBatchRequest
};
